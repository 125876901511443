const LOW = "Low";
const MED = "Med";
const HIGH = "High";

const NUM_LOW = 0;
const NUM_MED = 1;
const NUM_HIGH = 2;

const get = {};
get[NUM_HIGH] = HIGH;
get[NUM_MED] = MED;
get[NUM_LOW] = LOW;

export default {
  get,
  HIGH,
  MED,
  LOW,
  NUM_HIGH,
  NUM_MED,
  NUM_LOW
};
