const CLIENT = "Cliente";
const DRIVER = "Mensajero";
const ADMIN = "Admin";

const NUM_CLIENT = 0;
const NUM_DRIVER = 1;
const NUM_ADMIN = 2;

const get = {};
get[NUM_CLIENT] = CLIENT;
get[NUM_DRIVER] = DRIVER;
get[NUM_ADMIN] = ADMIN;

const options = Object.keys(get).map(key => ({
  text: get[key],
  value: parseInt(key)
}));

export default {
  get,
  options,
  CLIENT,
  DRIVER,
  ADMIN,
  NUM_CLIENT,
  NUM_DRIVER,
  NUM_ADMIN
};
