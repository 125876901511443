<template>
  <div>
    <b-card>
      <div class="p-3">
        <logs-header
          :loading-services="isLoading"
          @refresh="getListing('logs-header')"
        />
        <b-row class="mt-4">
          <div class="mx-3 mr-lg-0 logs-search">
            <z-dropdown
              id="dropdown-severity"
              v-model="selectedActivity"
              search
              no-caret
              :placeholder="
                selectedActivity == null ? 'Busca por actividad' : null
              "
              :show-header="false"
              :options="activityOptions"
              name="Actividad"
              variant="white"
            />
          </div>
          <div class="mx-3 mr-lg-0 mt-3 my-md-3 mt-lg-0 logs-filter">
            <z-dropdown
              id="dropdown-rol"
              v-model="selectedRole"
              :show-header="false"
              :options="rolOptions"
              name="Rol"
            />
          </div>
          <div class="mx-3 mr-lg-0 mt-3 my-md-3 mt-lg-0 logs-filter">
            <z-dropdown
              id="dropdown-severity"
              v-model="selectedSeverity"
              :show-header="false"
              :options="severityOptions"
              name="Severidad"
            />
          </div>
          <div
            v-show="selectedRole != null"
            class="mx-3 mr-lg-0 mt-3 my-md-3 mt-lg-0 logs-filter"
          >
            <!-- User Filters -->
            <z-dropdown-clients
              v-show="selectedRole === isClient"
              v-model="selectedClient"
            />
            <z-dropdown-admins
              v-show="selectedRole === isAdmin"
              v-model="selectedAdmin"
            />
            <z-dropdown-drivers
              v-show="selectedRole === isDriver"
              v-model="selectedDriver"
            />
          </div>
          <div class="mx-3 mr-lg-0 mt-3 my-md-3 mt-lg-0 logs-filter">
            <z-dropdown
              id="dropdown-date"
              v-model="selectedDate"
              :show-header="false"
              :options="dateOptions"
              name="Fecha"
            />
          </div>
          <div
            v-show="showDatePicker"
            class="mx-3 mr-lg-0 mt-3 my-md-3 mt-lg-0 logs-filter"
          >
            <z-date-picker
              v-model="dateFrom"
              calendar-class="date-picker"
              :disabled-dates="disableDatesFrom"
              input-class="form-control bg-white w-100"
              placeholder="Desde"
            />
          </div>
          <div
            v-show="showDatePicker"
            class="mx-3 mr-lg-0 mt-3 my-md-3 mt-lg-0 logs-filter"
          >
            <z-date-picker
              v-model="dateUntil"
              calendar-class="date-picker"
              :disabled-dates="disableDatesUntil"
              input-class="form-control bg-white w-100"
              placeholder="Hasta"
            />
          </div>
          <z-button
            v-if="isFiltered"
            class="mx-3 mr-lg-0 mt-3 my-md-3 mt-lg-0 logs-filter"
            variant="link"
            size="sm"
            @click="eraseFilters"
          >
            Limpiar Filtros
          </z-button>
        </b-row>
        <b-row>
          <b-col>
            <z-table
              class="mt-4"
              hover
              responsive="sm"
              :busy="isLoading"
              :items="listing"
              :fields="fields"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <loading-spinner />
                </div>
              </template>
              <template v-slot:empty>
                <p class="text-center py-5 my-5 font-weight-semi-bold">
                  No hay actividades registradas en este filtro
                </p>
              </template>
              <!-- Change styles in headers -->
              <template v-slot:head(createdAt)>
                <z-table-head-sort :order.sync="sortOrder" class="header-date">
                  Fecha
                </z-table-head-sort>
              </template>

              <!-- Change styles in cells -->
              <template v-slot:cell(rol)="row">
                {{ rolText(row.item) }}
              </template>

              <template v-slot:cell(severity)="row">
                <div class="text-center">
                  <z-icon :name="priority(row.item.severity)" size="20" />
                </div>
              </template>
            </z-table>
            <z-table-pagination
              :total-rows="listingTotal"
              :per-page.sync="perPage"
              :current-page.sync="currentPage"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import DateFilters from "@zubut/common/src/constants/filters/date";
import ZDatePicker from "@zubut/common/src/components/ZDatePicker";
import Activity from "@zubut/common/src/constants/logs/activity";
import Owner from "@zubut/common/src/constants/logs/owner";
import Priority from "@zubut/common/src/constants/logs/priority";
import Severity from "@zubut/common/src/constants/logs/severity";
import Users from "@zubut/common/src/constants/logs/users";
import { parseQueryParam } from "@zubut/common/src/utils/strings";
import { dateRange } from "@zubut/common/src/utils/time";
import ZDropdown from "@zubut/common/src/components/ZDropdown.vue";
import ZDropdownClients from "@/app/components/ZDropdownClients.vue";
import ZDropdownAdmins from "@/app/components/ZDropdownAdmins.vue";
import ZDropdownDrivers from "@/app/components/ZDropdownDrivers.vue";
import ZTableHeadSort from "@zubut/common/src/components/ZTableHeadSort";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import { formatISO, formatToISO } from "@zubut/common/src/utils/time";
import { makeShortUUID } from "@/utils/strings";
import * as mutation from "@/store/modules/logs/mutations-types";
import filtering from "@/mixins/filtering";
import LogsHeader from "./LogsHeader";
import _debounce from "lodash/debounce";

const moduleName = "logs";

export default {
  name: "Logs",

  components: {
    LogsHeader,
    ZDatePicker,
    ZDropdown,
    ZDropdownClients,
    ZDropdownAdmins,
    ZDropdownDrivers,
    ZTable,
    ZTableHeadSort,
    ZTablePagination
  },

  mixins: [filtering("logs")],

  data() {
    return {
      isLoading: false,
      where: {},
      fields: [
        {
          key: "severity",
          label: "Severidad"
        },
        {
          key: "serviceId",
          label: "ID",
          formatter: value => makeShortUUID(value)
        },
        {
          key: "createdAt",
          label: "Fecha",
          formatter: val => formatISO(val, "d 'de' LLLL 'de' Y H:mm")
        },
        { key: "title", label: "Actividad" },
        { key: "owner", label: "Responsable" },
        { key: "rol", label: "Rol" }
      ],
      severityOptions: [{ text: "Todos", value: null }].concat(
        Severity.options
      ),
      rolOptions: [{ text: "Todos", value: null }].concat(Owner.options),
      activityOptions: [{ text: "Todos", value: null }].concat(
        Activity.options
      ),
      options: Activity.options,
      dateOptions: DateFilters.options
    };
  },

  computed: {
    listing() {
      return this.$store.getters[`${moduleName}/getLisintg`];
    },
    sortOrder: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterSortOrder`];
      },
      set(val) {
        this.setFilter("sort", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_SORT_ORDER}`,
          val
        );
      }
    },
    searchText: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterText`];
      },
      set(val) {
        this.setFilter("search", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_TEXT}`,
          val
        );
      }
    },
    selectedDate: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterDate`];
      },
      set(val) {
        this.setFilter("date", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_DATE}`,
          val
        );
      }
    },
    dateFrom: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterDateFrom`];
      },
      set(val) {
        this.setFilter("from", val?.getTime());
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_DATE_FROM}`,
          val
        );
      }
    },
    dateUntil: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterDateUntil`];
      },
      set(val) {
        this.setFilter("until", val?.getTime());
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_DATE_UNTIL}`,
          val
        );
      }
    },
    selectedActivity: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterActivity`];
      },
      set(val) {
        this.setFilter("activity", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_ACTIVITY}`,
          val
        );
      }
    },
    selectedSeverity: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterSeverity`];
      },
      set(val) {
        this.setFilter("severity", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_SEVERITY}`,
          val
        );
      }
    },
    selectedRole: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterRole`];
      },
      set(val) {
        this.setFilter("rol", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_ROLE}`,
          val
        );
      }
    },
    selectedAdmin: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterAdmin`];
      },
      set(val) {
        this.setFilter("admin", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_ADMIN}`,
          val
        );
      }
    },
    selectedClient: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterClient`];
      },
      set(val) {
        this.setFilter("client", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_CLIENT}`,
          val
        );
      }
    },
    selectedDriver: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterDriver`];
      },
      set(val) {
        this.setFilter("driver", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_DRIVER}`,
          val
        );
      }
    },
    selectedService: {
      get() {
        return this.$store.getters[`${moduleName}/getListingFilterService`];
      },
      set(val) {
        this.setFilter("service", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_LISTING_FILTER_SERVICE}`,
          val
        );
      }
    },
    listingTotal() {
      return this.$store.getters[`${moduleName}/getListingTotal`];
    },
    isClient() {
      return Users.NUM_CLIENT;
    },
    isAdmin() {
      return Users.NUM_ADMIN;
    },
    isDriver() {
      return Users.NUM_DRIVER;
    },
    isFiltered() {
      return (
        this.selectedDate !== DateFilters.NUM_TODAY ||
        this.selectedActivity !== null ||
        this.selectedSeverity !== null ||
        this.selectedRole !== null ||
        this.selectedService !== null
      );
    },
    disableDatesFrom() {
      let date;
      if (this.dateUntil != null) {
        date = new Date(this.dateUntil);
      } else {
        date = new Date();
      }
      return { from: date };
    },
    disableDatesUntil() {
      let date;
      if (this.dateFrom != null) {
        date = new Date(this.dateFrom);
      } else {
        date = new Date();
      }
      return { to: date };
    },
    showDatePicker() {
      return this.selectedDate === DateFilters.NUM_CUSTOM;
    }
  },

  created() {
    this.setRouteFilters();

    /* Using debounce avoids duplicate calls caused by watchers */
    this.defaultListener = _debounce(
      () => this.getListing("defaultListener"),
      100
    );
    this.searchFilterListener = _debounce(
      () => this.getListing("searchFilterListener"),
      600
    );
    this.defaultListener();
  },

  methods: {
    setRouteFilters() {
      const query = { ...this.$route.query };

      query.search = parseQueryParam(query.search);
      query.page = parseQueryParam(query.page);
      query.show = parseQueryParam(query.show);
      query.date = parseQueryParam(query.date);
      query.from = parseQueryParam(query.from);
      query.until = parseQueryParam(query.until);
      query.rol = parseQueryParam(query.rol);
      query.admin = parseQueryParam(query.admin);
      query.client = parseQueryParam(query.client);
      query.driver = parseQueryParam(query.driver);
      query.activity = parseQueryParam(query.activity);
      query.severity = parseQueryParam(query.severity);
      query.service = parseQueryParam(query.service);

      this.selectedDate = query.date ?? DateFilters.NUM_TODAY;
      this.dateFrom = query.from ? new Date(query.from) : null;
      this.dateUntil = query.until ? new Date(query.until) : null;
      this.selectedActivity = query.activity ?? null;
      this.selectedSeverity = query.severity ?? null;
      this.selectedRole = query.rol ?? null;
      this.selectedAdmin = query.admin ?? null;
      this.selectedClient = query.client ?? null;
      this.selectedDriver = query.driver ?? null;
      this.selectedService = query.service ?? null;

      this.$router
        .replace({
          name: this.$route.name,
          query
        })
        .catch(() => {});
    },

    rolText(item) {
      if (item.rol === this.isAdmin) {
        return Users.getAdmin[item.adminType];
      } else return Users.get[item.rol];
    },

    priority(type) {
      return Priority.get[type] + "Priority";
    },

    eraseFilters() {
      this.selectedDate = DateFilters.NUM_TODAY;
      this.dateFrom = null;
      this.dateUntil = null;
      this.selectedActivity = null;
      this.selectedSeverity = null;
      this.selectedRole = null;
      this.selectedClient = null;
      this.selectedDriver = null;
      this.selectedAdmin = null;
      this.selectedService = null;
    },

    getListingAndResetPagination() {
      this.paginationReset();
      this.getListing("getListingAndResetPagination");
    },

    paginationReset() {
      this.currentPage = 1;
    },

    getListing() {
      this.isLoading = true;
      const where = this.buildWhere();
      this.$store
        .dispatch(`${moduleName}/getListing`, {
          where,
          filter: { ...this.pagination, order: `date ${this.sortOrder}` }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    buildWhere() {
      const where = {};
      if (this.selectedRole != null) where.owner = this.selectedRole;
      if (this.selectedActivity != null)
        where.title = Activity.get[this.selectedActivity];
      if (this.selectedSeverity != null) where.severity = this.selectedSeverity;

      //User filters
      if (this.selectedRole === this.isClient && this.selectedClient)
        where.clientId = this.selectedClient;

      if (this.selectedRole === this.isDriver && this.selectedDriver)
        where.driverId = this.selectedDriver;

      if (this.selectedRole === this.isAdmin && this.selectedAdmin)
        where.adminId = this.selectedAdmin;

      if (this.selectedService) {
        where.serviceId = this.selectedService;
        return where;
      }

      //Date Filter
      let from = null;
      let until = null;

      if (
        this.selectedDate === DateFilters.NUM_CUSTOM &&
        this.dateFrom &&
        this.dateUntil
      ) {
        from = formatToISO(this.dateFrom);
        until = formatToISO(this.dateUntil);
      } else if (
        this.selectedDate !== null &&
        this.selectedDate !== DateFilters.NUM_CUSTOM
      ) {
        const newDate = dateRange(this.selectedDate);
        from = formatToISO(newDate.from);
        until = formatToISO(newDate.until);
      }

      if (from && until) {
        where.and = [
          {
            date: {
              gte: from
            }
          },
          {
            date: {
              lte: until
            }
          }
        ];
      }

      return where;
    }
  }
};
</script>

<style lang="scss" scoped>
.logs-filter,
.logs-search {
  width: 100%;
}

@include media-breakpoint-up(lg) {
  .logs-search {
    width: 368px;
  }

  .logs-filter {
    width: auto;
  }
}
</style>
