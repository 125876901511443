<template>
  <z-dropdown
    search
    :options="driversOptions"
    :loading="loadingDrivers"
    :max-height="maxHeight"
    :name="name"
    :value="value"
    :variant="variant"
    :placeholder="placeholder"
    :rounded="rounded"
    :search-placeholder="searchPlaceholder"
    v-bind="$attrs"
    @input="$emit('input', $event)"
    @option-change="handleChange"
    @search-change="searchDriver"
  />
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import Drivers from "@/services/drivers";
import DriverStatus from "@/constants/drivers/status";
import _debounce from "lodash/debounce";

export default {
  name: "ZDropdownDrivers",

  components: {
    ZDropdown
  },

  props: {
    status: {
      type: [Array, Number],
      default: () => [
        DriverStatus.NUM_NEW,
        DriverStatus.NUM_PENDING,
        DriverStatus.NUM_APPROVED,
        DriverStatus.NUM_DENIED,
        DriverStatus.NUM_BILLING_REVISION,
        DriverStatus.NUM_DOCS_REVISION,
        DriverStatus.NUM_ACTIVE,
        DriverStatus.NUM_BLOCKED
      ]
    },
    firstOption: {
      type: [Object, Boolean],
      default: () => ({ text: "Todos", value: null })
    },
    maxHeight: {
      type: String,
      default: "400px"
    },
    name: {
      type: String,
      default: "Mensajero"
    },
    placeholder: {
      type: String,
      default: ""
    },
    rounded: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: "Buscar mensajero"
    },
    value: {
      type: [Number, String],
      default: null
    },
    variant: {
      type: String,
      default: "default",
      validator: val => ["default", "white"].indexOf(val) > -1
    },
    exclude: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      loadingDrivers: true,
      driversOptions: this.firstOption ? [this.firstOption] : []
    };
  },

  computed: {
    hasCurrentOption() {
      return this.driversOptions.find(op => this.value === op?.value);
    },
    selectedCity() {
      return this.$store.getters["cities/getCity"];
    }
  },

  watch: {
    value() {
      if (!this.hasCurrentOption) {
        this.getDriversOptions();
      }
    },
    selectedCity() {
      this.getDriversOptions();
    }
  },

  created() {
    this.searchDriver = _debounce(searchText => {
      this.getDriversOptions(searchText);
    }, 600);
    if (!this.driversOptions.length) {
      this.getDriversOptions();
    }
  },

  methods: {
    getDriversOptions(searchText = "") {
      this.loadingDrivers = true;
      let where = {
        like: searchText,
        status: this.status
      };
      if (this.selectedCity) {
        where.cityId = this.selectedCity;
      }
      if (!searchText && !this.hasCurrentOption) {
        searchText = this.value;
      }
      if (this.exclude != null) {
        where = { ...where, id: { nin: this.exclude } };
      }
      searchText = searchText ? searchText.toLowerCase() : searchText;
      if (
        searchText === "" &&
        this.$store.getters["drivers/driversFilterOptions"].length > 1
      ) {
        this.driversOptions = this.$store.getters[
          "drivers/driversFilterOptions"
        ];
        this.loadingDrivers = false;
      } else {
        if (searchText === "") {
          this.$store
            .dispatch("drivers/getDriversFilter", where)
            .then(_ => {
              this.driversOptions = this.$store.getters[
                "drivers/driversFilterOptions"
              ];
            })
            .finally(() => {
              this.loadingDrivers = false;
            });
        } else {
          Drivers.nameListing({
            filter: { skip: 0, limit: 5, order: "lastLogin DESC" },
            where
          })
            .then(res => {
              if (this.firstOption) {
                this.driversOptions = [this.firstOption].concat(
                  res.data.map(user => ({
                    value: user.id,
                    text: user.name
                  }))
                );
              } else {
                this.driversOptions = res.data.map(user => ({
                  value: user.id,
                  text: user.name
                }));
              }

              if (!this.hasCurrentOption && this.value) {
                this.getSelectedDriver();
              }
            })
            .catch(error => {
              this.$captureError(error);
            })
            .finally(() => {
              this.loadingDrivers = false;
            });
        }
      }
    },
    getSelectedDriver() {
      this.loadingDrivers = true;
      Drivers.findById({
        id: this.value
      })
        .then(res => {
          this.driversOptions.push({ value: res.id, text: res.name });
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loadingDrivers = false;
        });
    },
    handleChange(option) {
      this.$emit("change", option);
    }
  }
};
</script>
