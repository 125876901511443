<template>
  <b-row>
    <b-col class="d-flex flex-column flex-md-row justify-content-md-between">
      <span class="card-title">
        Registros del Sistema
      </span>
      <div class="d-flex">
        <z-button
          v-tooltip.top="'Refrescar'"
          fit="auto"
          variant="secondary"
          class="mr-3 px-4"
          @click="handleRefresh"
        >
          <font-awesome-icon
            :class="{ 'animation-spin-reverse': spinRefreshButton }"
            icon="reload"
            size="lg"
          />
        </z-button>
        <!-- <logs-download class="order-1 order-md-0 mr-md-3" /> -->
      </div>
    </b-col>
  </b-row>
</template>

<script>
import LogsDownload from "./LogsDownload";

export default {
  name: "LogsHeader",

  components: {
    //LogsDownload
  },

  props: {
    loadingLogs: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hasError: false,
      message: "",
      emittedRefresh: false
    };
  },

  computed: {
    spinRefreshButton() {
      return this.emittedRefresh && this.loadingLogs;
    }
  },

  watch: {
    loadingLogs(val) {
      if (val === false) {
        this.emittedRefresh = false;
      }
    }
  },

  methods: {
    handleDownloadError(message) {
      this.$emit("error", message);
    },
    handleRefresh() {
      this.emittedRefresh = true;
      this.$emit("refresh");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@zubut/common/src/styles/animation/spin.scss";
</style>
