const CLIENT = "Cliente";
const DRIVER = "Mensajero";
const ADMIN = "Admin";

const NUM_CLIENT = 0;
const NUM_DRIVER = 1;
const NUM_ADMIN = 2;

const get = {};
get[NUM_CLIENT] = CLIENT;
get[NUM_DRIVER] = DRIVER;
get[NUM_ADMIN] = ADMIN;

const SUPER_ADMIN = "Super Admin";
const OPERATIONS = "Operaciones";
const ACCOUTING = "Contabilidad";
const SALES = "Ventas";
const MARKETING = "Marketing";

const NUM_SUPER_ADMIN = 0;
const NUM_OPERATIONS = 1;
const NUM_ACCOUTING = 2;
const NUM_SALES = 3;
const NUM_MARKETING = 4;

const getAdmin = {};
getAdmin[NUM_SUPER_ADMIN] = SUPER_ADMIN;
getAdmin[NUM_OPERATIONS] = OPERATIONS;
getAdmin[NUM_ACCOUTING] = ACCOUTING;
getAdmin[NUM_SALES] = SALES;
getAdmin[NUM_MARKETING] = MARKETING;

export default {
  get,
  getAdmin,
  CLIENT,
  DRIVER,
  ADMIN,
  NUM_CLIENT,
  NUM_DRIVER,
  NUM_ADMIN,
  SUPER_ADMIN,
  OPERATIONS,
  ACCOUTING,
  SALES,
  MARKETING,
  NUM_SUPER_ADMIN,
  NUM_OPERATIONS,
  NUM_ACCOUTING,
  NUM_SALES,
  NUM_MARKETING
};
