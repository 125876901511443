<template>
  <z-dropdown
    search
    :options="adminsOptions"
    :loading="loadingAdmins"
    :max-height="maxHeight"
    :name="name"
    :value="value"
    :variant="variant"
    :placeholder="placeholder"
    :search-placeholder="searchPlaceholder"
    v-bind="$attrs"
    @input="$emit('input', $event)"
    @search-change="searchAdmin"
  />
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import Admins from "@/services/admins";
import _debounce from "lodash/debounce";

export default {
  name: "ZDropdownAdmins",

  components: {
    ZDropdown
  },

  props: {
    firstOption: {
      type: [Object, Boolean],
      default: () => ({ text: "Todos", value: null })
    },
    maxHeight: {
      type: String,
      default: "400px"
    },
    name: {
      type: String,
      default: "Admin"
    },
    placeholder: {
      type: String,
      default: ""
    },
    searchPlaceholder: {
      type: String,
      default: "Buscar admin"
    },
    value: {
      type: [Number, String],
      default: null
    },
    variant: {
      type: String,
      default: "default",
      validator: val => ["default", "white"].indexOf(val) > -1
    },
    exclude: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      loadingAdmins: true,
      adminsOptions: this.firstOption ? [this.firstOption] : []
    };
  },

  computed: {
    hasCurrentOption() {
      return this.adminsOptions.find(op => this.value === op?.value);
    }
  },

  watch: {
    value() {
      if (!this.hasCurrentOption) {
        this.getAdminsOptions();
      }
    }
  },

  created() {
    this.searchAdmin = _debounce(searchText => {
      this.getAdminsOptions(searchText);
    }, 600);
    if (!this.adminsOptions.length) {
      this.getAdminsOptions();
    }
  },

  methods: {
    getAdminsOptions(searchText = "") {
      this.loadingAdmins = true;
      let where = {
        like: searchText
      };
      if (!searchText && !this.hasCurrentOption) {
        searchText = this.value;
      }
      if (this.exclude != null) {
        where = { ...where, id: { nin: this.exclude } };
      }
      searchText = searchText ? searchText.toLowerCase() : searchText;
      if (
        searchText === "" &&
        this.$store.getters["admin/adminsFilterOptions"].length > 1
      ) {
        this.adminsOptions = this.$store.getters["admin/adminsFilterOptions"];
        this.loadingAdmins = false;
      } else {
        if (searchText === "") {
          this.$store.dispatch("admin/getAdminsFilter");
          this.adminsOptions = this.$store.getters["admin/adminsFilterOptions"];
          this.loadingAdmins = false;
        } else {
          Admins.listing({
            filter: { skip: 0, limit: 5, order: "lastLogin DESC" },
            where
          })
            .then(res => {
              if (this.firstOption) {
                this.adminsOptions = [this.firstOption].concat(
                  res.data.map(user => ({
                    value: user.id,
                    text: user.name ? user.name : "admin"
                  }))
                );
              } else {
                this.adminsOptions = res.data.map(user => ({
                  value: user.id,
                  text: user.name ? user.name : "admin"
                }));
              }

              if (!this.hasCurrentOption && this.value) {
                this.getSelectedAdmin();
              }
            })
            .catch(error => {
              this.$captureError(error);
            })
            .finally(() => {
              this.loadingAdmins = false;
            });
        }
      }
    },
    getSelectedAdmin() {
      this.loadingAdmins = true;
      Admins.findById({
        id: this.value
      })
        .then(res => {
          this.adminsOptions.push({ value: res.id, text: res.name });
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loadingAdmins = false;
        });
    }
  }
};
</script>
