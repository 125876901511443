<template>
  <z-button
    v-tooltip.top="'Descargar Actividad'"
    class="order-1 order-md-0 mr-md-3 px-4"
    variant="secondary"
    fit="auto"
  >
    <font-awesome-icon icon="download" size="lg" />
  </z-button>
</template>

<script>
export default {
  name: "LogsDownload"
};
</script>
