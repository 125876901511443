const INFO = "Alta";
const WARN = "Media";
const ERROR = "Baja";

const NUM_INFO = 0;
const NUM_WARN = 1;
const NUM_ERROR = 2;

const get = {};
get[NUM_INFO] = INFO;
get[NUM_WARN] = WARN;
get[NUM_ERROR] = ERROR;

const options = Object.keys(get).map(key => ({
  text: get[key],
  value: parseInt(key)
}));

export default {
  get,
  options,
  INFO,
  WARN,
  ERROR,
  NUM_INFO,
  NUM_WARN,
  NUM_ERROR
};
